<template>
  <div>
    <el-date-picker
      v-model="model"
      class="w-85"
      type="date"
      :placeholder="placeholder"
      placement="bottom-start"
      format="dd.MM.yyyy"
      :disabled="disabled"
      :picker-options="pickerOption"
      value-format="yyyy-MM-dd"
    />
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: [String, Boolean],
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    modell: {
      type: String,
      default: 'Дата',
    },
    value: [String, Number],
    placeholder: {
      type: String,
      default: 'Дата',
    },
  },
  data() {
    return {
      model: '',
      pickerOption: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          const date = new Date()
          date.setDate(date.getDate())
          return date < time.getTime()
        },
      },
    }
  },
  watch: {
    model(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.model = this.value
    },
  },
  mounted() {
    this.model = this.value
  },
}
</script>

<style>

</style>
