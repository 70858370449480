import {
  show,
  update,
  index,
  store,
  destroy,
  tree,
  warehouseProducts,
  warehouseProductsByWarehouse,
  warehouseProductsByBarcode,
  updateProductPrice,
  addPhotoProduct,
  updatePhotoProduct,
  productCategoryCharacteristics,
  getCharacteristicsValue,
  storeCharacteristicsValue,
  productsForPriceTag,
  indexNPCategories,
  showNPCategories,
  storeNPCategories,
  updateNPCategories,
  destroyNPCategories,
  newTree,
  modelsWithParents,
  calculateInstallmentPriceDolar
} from "@/api/product-category";

export const actions = {
  index({ commit }, query) {
    return new Promise((resolve, reject) => {
      index(query)
        .then((res) => {
          commit("SET_ITEMS", res.data);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  tree({ commit }, query) {
    return new Promise((resolve, reject) => {
      tree(query)
        .then((res) => {
          commit("SET_TREE", res.data);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  show({ commit }, query) {
    return new Promise((resolve, reject) => {
      show(query)
        .then((res) => {
          commit("SET_ITEM", res.data);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  store({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data)
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
  destroy({ commit }, id) {
    return new Promise((resolve, reject) => {
      destroy(id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  warehouseProducts({ commit }, query) {
    return new Promise((resolve, reject) => {
      warehouseProducts(query)
        .then((res) => {
          commit("SET_WAREHOUSE_PRODUCTS", res.data);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  warehouseProductsByWarehouse({ commit }, query) {
    return new Promise((resolve, reject) => {
      warehouseProductsByWarehouse(query)
        .then((res) => {
          commit("SET_WAREHOUSE_PRODUCTS_BY_WAAREHOUSE", res.data);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  warehouseProductsByBarcode({ commit }, query) {
    return new Promise((resolve, reject) => {
      warehouseProductsByBarcode(query)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateProductPrice({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateProductPrice(data)
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
  addPhotoProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      addPhotoProduct(data)
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
  updatePhotoProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      updatePhotoProduct(data)
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
  productCategoryCharacteristics({ commit }, data) {
    return new Promise((resolve, reject) => {
      productCategoryCharacteristics(data)
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
  getCharacteristicsValue({ commit }, data) {
    return new Promise((resolve, reject) => {
      getCharacteristicsValue(data)
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
  storeCharacteristicsValue({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeCharacteristicsValue(data)
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
  productsForPriceTag({ commit }, data) {
    return new Promise((resolve, reject) => {
      productsForPriceTag(data)
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
   // new-product-categories
   indexNPCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      indexNPCategories(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  showNPCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      showNPCategories(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  storeNPCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeNPCategories(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateNPCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateNPCategories(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  destroyNPCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      destroyNPCategories(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  newTree({ commit }, query) {
    return new Promise((resolve, reject) => {
      newTree(query)
        .then((res) => {
          commit("SET_NEW_TREE", res.data);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  modelsWithParents({ commit }, query) {
    return new Promise((resolve, reject) => {
      modelsWithParents(query)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  calculateInstallmentPriceDolar({ commit }, query) {
    return new Promise((resolve, reject) => {
      calculateInstallmentPriceDolar(query)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
