export const state = {
  item: {},
  items: {},
  tree: [],
  tree_rows: [],
  new_tree: [],
  new_tree_rows: [],
  warehouse_products: [],
  warehouse_products_by_warehouse: [],
}
