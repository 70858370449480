import request from '@/utils/request'

const slug = '/warehouses/parties'

export function index(params) {
  return request({
    url: `${slug}`,
    method: 'get',
    params,
  })
}

export function show(params) {
  return request({
    url: `${slug}/${params.id}`,
    method: 'get',
    params,
  })
}

export function store(data) {
  return request({
    url: slug,
    method: 'post',
    data,
  })
}

export function update(data) {
  return request({
    url: `${slug}/${data.id}`,
    method: 'put',
    data,
  })
}

export function destroy(id) {
  return request({
    url: `${slug}/${id}`,
    method: 'delete',
  })
}

export function storePhone(data) {
  return request({
    url: '/warehouses/store-phone-parties',
    method: 'post',
    data,
  })
}

export function storeOldPhoneIncome(data) {
  return request({
    url: '/warehouses/store-for-old-phone-income',
    method: 'post',
    data,
  })
}

export function storeSerialParties(data) {
  return request({
    url: '/warehouses/store-serial-parties',
    method: 'post',
    data,
  })
}
