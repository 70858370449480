export default [
  {
    path: '/marketplace/',
    name: 'Marketplace',
    component: () => import('@/views/marketplace/index.vue'),
    redirect: 'MarketplaceBannerCreate',
    meta: {
      middleware: 'Auth',
      pageTitle: 'Market',
    },
    children: [
      {
        path: 'banner',
        name: 'MarketplaceBannerCreate',
        component: () => import('@/views/marketplace/banner/index.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Banner',
        },
      },
      {
        path: 'quick-orders',
        name: 'QuickOrdersList',
        component: () => import('@/views/marketplace/quickOrders/index.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Tezkor buyurtmalar',
        },
      },
      {
        path: 'upload-photo',
        name: 'UploadProductPhotos',
        component: () => import('@/views/marketplace/uploadPhoto.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Mahsulotlar foto',
        },
      },
    ],
  },
]
