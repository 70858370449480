export default [
  {
    path: '/report/all-products-report',
    name: 'AllProductsReport',
    component: () => import('@/views/report/AllProductsReport.vue'),
    meta: {
      middleware: 'AuthAdmin',
      pageTitle: 'Отчёт по всем продуктам',
    },
  },
  {
    path: '/suggestions',
    name: 'SuggestionsList',
    component: () => import('@/views/suggestions/index.vue'),
    meta: {
      middleware: 'Auth',
      pageTitle: 'Takliflar',
      hasCreateButton: true,
      permissions: 'suggestions.store',
      createButtonName: "Qo'shish",
      breadcrumb: [
        {
          text: 'Takliflar',
          active: true,
        },
      ],
    },
  },
]
