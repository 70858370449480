import Vue from 'vue'
import { getItem } from '@/utils/storage'

Vue.directive('can', {
  bind(el, binding, vnode) {
    const { value } = binding
    let hasRole = false
    const user = getItem('user')
    const { perms } = user.role
    if (perms) {
      if (value && value instanceof Array) {
        hasRole = false
        value.forEach(element => {
          hasRole = hasRole || (perms.includes(element))
        })
      } else {
        hasRole = perms.includes(value)
        if (value === 'all') hasRole = true
      }
    }
    if (!hasRole) {
      el.style.display = 'none'
    }
  },
})
