import {
  show, update, index, store, destroy, transferProductsList, transferProductsShow, confirmTransferProducts,
  transferProductsStore, getVisiblePartyProducts, getVisibleContractProducts, getContractProducts, rejectProduct, rejectProductTransfer, rejectProductHiredWorker,
} from '@/api/warehouse'

export const actions = {
  index({ commit }, query) {
    return new Promise((resolve, reject) => {
      index(query).then(res => {
        commit('SET_ITEMS', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      show(id).then(res => {
        commit('SET_ITEM', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  store({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  destroy({ }, id) {
    return new Promise((resolve, reject) => {
      destroy(id).then(res => {
        resolve(res)
      }).catch(res => {
        reject(res)
      })
    })
  },
  transferProductsList({ commit }, query) {
    return new Promise((resolve, reject) => {
      transferProductsList(query).then(res => {
        commit('SET_TRANSFER_ITEMS', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  transferProductsShow({ commit }, id) {
    return new Promise((resolve, reject) => {
      transferProductsShow(id).then(res => {
        commit('SET_TRANSFER_ITEM', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  transferProductsStore({ commit }, data) {
    return new Promise((resolve, reject) => {
      transferProductsStore(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  getVisiblePartyProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      getVisiblePartyProducts(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  getVisibleContractProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      getVisibleContractProducts(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },

  getContractProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      getContractProducts(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  rejectProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      rejectProduct(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  rejectProductTransfer({ commit }, data) {
    return new Promise((resolve, reject) => {
      rejectProductTransfer(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  confirmTransferProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      confirmTransferProducts(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  rejectProductHiredWorker({ commit }, data) {
    return new Promise((resolve, reject) => {
      rejectProductHiredWorker(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
}
