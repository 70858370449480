export default [
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('@/views/extensions/acl/AccessControl.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/product-info-scanner',
    name: 'ScanerProduct',
    component: () => import('@/components/ScanerProduct.vue'),
    meta: {
      middleware: 'Auth',
    },
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import('@/views/apps/todo/Tasks.vue'),
    meta: {
      middleware: 'Auth',
    },
  },
  {
    path: '/kpi-employers',
    name: 'KpiEmployers',
    component: () => import('@/views/kpi/index.vue'),
    meta: {
      middleware: 'Auth',
      permissions: 'kpi',

    },
  },
  {
    path: '/blacklist',
    name: 'BlackListClients',
    component: () => import('@/views/blacklist/index.vue'),
    meta: {
      middleware: 'Auth',
      pageTitle: 'Qora ro`yhat',
      hasCreateButton: true,
      createButtonName: 'Qo\'shish',
      permissions: 'settings.blacklists-store',
      breadcrumb: [
        {
          text: 'Qora ro`yhat',
          active: true,
        },
      ],
    },
  },
]
