import i18n from '@/libs/i18n'

export const state = {
  personsCount: 0,
  persons: [],
  testPersonsCount: 0,
  testPersons: [],
  clientReport: null,
  client2Report: null,
  clientsReport: null,
  unit_of_measures: null,
  brandsList:[],
  sources: [
    {
      id: 1,
      name: i18n.t('Давлат фуқаролик хизмати ходими'),
      btnText: 'Тўлиқ рўйхат',
      titleText: 'Тўлиқ рўйхат',
    },
    {
      id: 2,
      name: i18n.t('"Эл-юрт умиди" жамғармаси степендиати'),
      btnText: 'Эл-юрт умиди',
      titleText: '«Эл-юрт умиди» жамғармаси стипендиянтлари',
    },
    {
      id: 3,
      name: i18n.t('Тараққиёт республика танлови битирувчиси'),
      btnText: 'Тараққиёт',
      titleText: '«Тараққиёт» танловчи ғолиблари ва финал босқичи иштирокчилари',
    },
    {
      id: 4,
      name: i18n.t('Давлат бошқаруви академияси'),
      btnText: 'ДБА',
      titleText: 'Давлат бошқаруви академияси битирувчилари',
    },
    {
      id: 5,
      name: i18n.t('Олий бизнес мактаби'),
      btnText: 'ОБМ',
      titleText: 'Бизнес ва тадбиркорлик олий мактаби битирувчилари',
    },
    {
      id: 6,
      name: i18n.t('Банк-молия академияси'),
      btnText: 'БМА',
      titleText: 'Банк-молия академияси битирувчилари',
    }],
}
