import axios from 'axios'
import { getToken, clearForLogout } from '@/utils/auth'
import router from '@/router'
import store from '@/store'
import { showToast } from '@/utils/toast'

import { errorsAlert } from './requestAlerts'

const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // url = base url + request url
  timeout: 5000000, // request timeout
})
const notLoader = [
  // '/classifiers/skills',
]
function loaderIncrease(config) {
  if (notLoader.indexOf(config.url) < 0) {
    store.dispatch('loader/increase')
  }
}
function loaderDecrease(config) {
  if (notLoader.indexOf(config.url) < 0) {
    store.dispatch('loader/decrease')
  }
}
function logout() {
  clearForLogout()
  return router.push({ name: 'auth-login' })
}

service.interceptors.request.use(
  config => {
    loaderIncrease(config)
    const token = getToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => Promise.reject(error),
)

service.interceptors.response.use(
  response => {
    loaderDecrease(response.config)
    const res = response.data
    if (response.status !== 200) {
      return Promise.reject(new Error(res.message || 'Error'))
    }
    return res
  },
  error => {
    loaderDecrease(error.config)
    if (error.response) {
      errorsAlert(error.response)
    } else {
      errorsAlert(error)
    }
    if (error.response.status == 401) {
      logout()
    }
    return Promise.reject(error.response)
  },
)

export default service
