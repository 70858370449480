export default [
  {
    path: '/contract/:id', // contract id
    name: 'payment-show',
    component: () => import('@/views/payment/create.vue'),
    meta: {
      middleware: 'Auth',
    },
  },
  {
    path: '/contract-old/:id', // contract id
    name: 'contract-old-show',
    component: () => import('@/views/payment/createForOldContract.vue'),
    meta: {
      middleware: 'Auth',
    },
  },
  {
    path: '/payments-confirmation',
    name: 'ConfirmPayment',
    component: () => import('@/views/payment/confirmation.vue'),
    meta: {
      middleware: 'Auth',
      pageTitle: 'Подтверждение оплаты',
    },
  },
  {
    path: '/payments-confirmation-director',
    name: 'ConfirmPaymentDirector',
    component: () => import('@/views/payment/confirmationDirector.vue'),
    meta: {
      middleware: 'Auth',
      pageTitle: 'Подтверждение оплаты',
    },
  },
  {
    path: '/click-transactions',
    name: 'ClickTransactions',
    component: () => import('@/views/payment/clickTransaction.vue'),
    meta: {
      middleware: 'Auth',
      pageTitle: 'Click tranzaksiyalar',
    },
  },
  // Autopay
  {
    path: '/autopay/',
    name: 'Autopay',
    component: () => import('@/views/autopay/index.vue'),
    meta: {
      middleware: 'Auth',
      pageTitle: 'Autopay',
      breadcrumb: [
        {
          text: 'Autopay',
          active: true,
        },
      ],
    },
    children: [
      {
        path: 'payments',
        name: 'AutopayList',
        component: () => import('@/views/autopay/payments.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Avto to`lovlar',
        },
      },
      {
        path: 'clients',
        name: 'AutopayClients',
        component: () => import('@/views/autopay/clients.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Avtoto`lov mijozlari',
        },
      },
      {
        path: 'client/:id',
        name: 'AutopayClient-show',
        component: () => import('@/views/autopay/client-show.vue'),
        meta: {
          middleware: 'Auth',
        },
      },
    ],
  },
]
