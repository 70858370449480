export const getters = {
  GET_ITEM: state => state.item,
  GET_TREE: state => state.tree,
  GET_TREE_ROWS: state => state.tree_rows,
  GET_NEW_TREE: state => state.new_tree,
  GET_NEW_TREE_ROWS: state => state.new_tree_rows,
  GET_ITEMS: state => state.items,
  GET_WAREHOUSE_PRODUCTS: state => state.warehouse_products,
  WAREHOUSE_PRODUCTS_BY_WAREHOUSE: state => state.warehouse_products_by_warehouse,
}
