// src/pusher.js (Create this file for Pusher setup)
import Pusher from 'pusher-js'

// Configure your Pusher instance
const pusher = new Pusher('202d41b070c6285e35ab', {
  cluster: 'ap2',
  encrypted: true,
})
// PUSHER_APP_ID=1905967
// PUSHER_APP_KEY=202d41b070c6285e35ab
// PUSHER_APP_SECRET=9a3d46cb85387bc0d29e
// PUSHER_APP_CLUSTER=ap2
// console.log(7777, pusher)

// Export for use in your components
export default pusher
