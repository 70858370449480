import request from '@/utils/request'

export function index(query) {
  return request({
    url: '/notifications',
    method: 'get',
    params: query,
  })
}

export function show(id) {
  return request({
    url: `/notifications/${id}`,
    method: 'get',
  })
}

export function update(data) {
  return request({
    url: `notifications/${data.id}`,
    method: 'put',
    data,
  })
}
export function saveToken(data) {
  return request({
    url: 'firebase/save-token',
    method: 'put',
    data,
  })
}
