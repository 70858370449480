import {
  show, update, index, store, destroy, qOrdersList, qOrderUpdate, qOrderShow, qOrderDestroy, getTgMessages, getTgMessage, sendTgMessage, getTgOrder, getTgMessageByOrder, getChats, readMessage,
} from '@/api/shop'

export const actions = {
  index({ commit }, query) {
    return new Promise((resolve, reject) => {
      index(query).then(res => {
        commit('SET_ITEMS', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      show(id).then(res => {
        commit('SET_ITEM', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  store({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  destroy({ }, id) {
    return new Promise((resolve, reject) => {
      destroy(id).then(res => {
        resolve(res)
      }).catch(res => {
        reject(res)
      })
    })
  },
  //
  qOrdersList({ commit }, data) {
    return new Promise((resolve, reject) => {
      qOrdersList(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  qOrderShow({ commit }, data) {
    return new Promise((resolve, reject) => {
      qOrderShow(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  qOrderDestroy({ commit }, data) {
    return new Promise((resolve, reject) => {
      qOrderDestroy(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  qOrderUpdate({ commit }, data) {
    return new Promise((resolve, reject) => {
      qOrderUpdate(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  getTgMessages({ commit }, data) {
    return new Promise((resolve, reject) => {
      getTgMessages(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  getTgMessage({ commit }, data) {
    return new Promise((resolve, reject) => {
      getTgMessage(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  sendTgMessage({ commit }, data) {
    return new Promise((resolve, reject) => {
      sendTgMessage(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  getTgOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      getTgOrder(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  getTgMessageByOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      getTgMessageByOrder(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  getChats({ commit }, data) {
    return new Promise((resolve, reject) => {
      getChats(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  readMessage({ commit }, data) {
    return new Promise((resolve, reject) => {
      readMessage(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
}
