import {
  show, update, index, store, destroy, paymentStore, paymentList, confirmDailyPayments, paymentShow, uploadCourtDecision, getOldContracts,
  getDailyPayments, confirmPayment, getContractProductInfo, updateImagesConfirmation, commentStore, getComments, sellForCash, rejectContract, sendCourt,
  oldContractShow, paymentStoreForOldContract, getPaymentProductInfo, rejectByProduct, paymentUpdate, paymentDelete, makeAktSverka, uploadAddDocument, deleteAddDocument
} from '@/api/contracts'

export const actions = {
  index({ commit }, query) {
    return new Promise((resolve, reject) => {
      index(query).then(res => {
        commit('SET_ITEMS', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  indexAll({ commit }, query) {
    return new Promise((resolve, reject) => {
      index(query).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      show(id).then(res => {
        commit('SET_ITEM', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  store({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  destroy({ }, id) {
    return new Promise((resolve, reject) => {
      destroy(id).then(res => {
        resolve(res)
      }).catch(res => {
        reject(res)
      })
    })
  },
  paymentStore({ commit }, query) {
    return new Promise((resolve, reject) => {
      paymentStore(query).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  paymentList({ commit }, query) {
    return new Promise((resolve, reject) => {
      paymentList(query).then(res => {
        commit('SET_PAYMENT_ITEMS', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  confirmDailyPayments({ commit }, query) {
    return new Promise((resolve, reject) => {
      confirmDailyPayments(query).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getDailyPayments({ commit }, query) {
    return new Promise((resolve, reject) => {
      getDailyPayments(query).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  confirmPayment({ commit }, query) {
    return new Promise((resolve, reject) => {
      confirmPayment(query).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // comments
  commentStore({ commit }, data) {
    return new Promise((resolve, reject) => {
      commentStore(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getComments({ commit }, query) {
    return new Promise((resolve, reject) => {
      getComments(query).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateImagesConfirmation({ commit }, query) {
    return new Promise((resolve, reject) => {
      updateImagesConfirmation(query).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  sellForCash({ commit }, data) {
    return new Promise((resolve, reject) => {
      sellForCash(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  rejectContract({ commit }, data) {
    return new Promise((resolve, reject) => {
      rejectContract(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  paymentShow({ commit }, data) {
    return new Promise((resolve, reject) => {
      paymentShow(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  sendCourt({ commit }, data) {
    return new Promise((resolve, reject) => {
      sendCourt(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  uploadCourtDecision({ commit }, data) {
    return new Promise((resolve, reject) => {
      uploadCourtDecision(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getContractProductInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      getContractProductInfo(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getOldContracts({ commit }, data) {
    return new Promise((resolve, reject) => {
      getOldContracts(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  oldContractShow({ commit }, data) {
    return new Promise((resolve, reject) => {
      oldContractShow(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  paymentStoreForOldContract({ commit }, data) {
    return new Promise((resolve, reject) => {
      paymentStoreForOldContract(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getPaymentProductInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      getPaymentProductInfo(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  rejectByProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      rejectByProduct(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  makeAktSverka({ commit }, data) {
    return new Promise((resolve, reject) => {
      makeAktSverka(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  paymentUpdate({ commit }, data) {
    return new Promise((resolve, reject) => {
      paymentUpdate(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  paymentDelete({ commit }, data) {
    return new Promise((resolve, reject) => {
      paymentDelete(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  uploadAddDocument({ commit }, data) {
    return new Promise((resolve, reject) => {
      uploadAddDocument(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  deleteAddDocument({ commit }, data) {
    return new Promise((resolve, reject) => {
      deleteAddDocument(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
}
