import {
  show, update, index, store, destroy, indexNum,
  showNum,
  storeNum,
  updateNum,
  destroyNum,
} from '@/api/attach-letter'

export const actions = {
  index({ commit }, query) {
    return new Promise((resolve, reject) => {
      index(query).then(res => {
        commit('SET_ITEMS', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      show(id).then(res => {
        commit('SET_ITEM', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  store({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  destroy({ }, id) {
    return new Promise((resolve, reject) => {
      destroy(id).then(res => {
        resolve(res)
      }).catch(res => {
        reject(res)
      })
    })
  },
  // attach number
  indexNum({ commit }, query) {
    return new Promise((resolve, reject) => {
      indexNum(query).then(res => {
        commit('SET_ITEMS_NUMBER', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  showNum({ commit }, id) {
    return new Promise((resolve, reject) => {
      showNum(id).then(res => {
        commit('SET_ITEM_NUMBER', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  storeNum({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeNum(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  updateNum({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateNum(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  destroyNum({ }, id) {
    return new Promise((resolve, reject) => {
      destroyNum(id).then(res => {
        resolve(res)
      }).catch(res => {
        reject(res)
      })
    })
  },
}
