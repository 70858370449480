import request from '@/utils/request'

export function clients(params) {
  return request({
    url: '/clients',
    method: 'get',
    params,
  })
}
export function fileUpload(data) {
  return request({
    url: '/files',
    method: 'post',
    data,
  })
}
export function create(data) {
  return request({
    url: '/clients',
    method: 'post',
    data,
  })
}
export function show(params) {
  return request({
    url: `/clients/${params.id}`,
    method: 'get',
    params,
  })
}
export function deleteById(id) {
  return request({
    url: `/clients/${id}`,
    method: 'delete',
  })
}
export function createManual(data) {
  return request({
    url: '/client-store-manual',
    method: 'post',
    data,
  })
}
export function clientStoreManualNonKatm(data) {
  return request({
    url: '/client-store-manual-non-katm',
    method: 'post',
    data,
  })
}

export function originalClients(params) {
  return request({
    url: '/original-clients',
    method: 'get',
    params,
  })
}
export function clientCashback(params) {
  return request({
    url: '/client/get-cashback',
    method: 'get',
    params,
  })
}
