import {
  fileUpload, create, show, clients, createManual, clientStoreManualNonKatm, originalClients, clientCashback,
} from '@/api/client'

export const actions = {
  fileUpload({ commit }, params) {
    return new Promise((resolve, reject) => {
      fileUpload(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  clients({ commit }, data) {
    return new Promise((resolve, reject) => {
      clients(data)
        .then(res => {
          commit('SET_ITEMS', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      create(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  show({ commit }, data) {
    return new Promise((resolve, reject) => {
      show(data)
        .then(res => {
          commit('SET_ITEM', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createManual({ commit }, data) {
    return new Promise((resolve, reject) => {
      createManual(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  originalClients({ commit }, data) {
    return new Promise((resolve, reject) => {
      originalClients(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  clientCashback({ commit }, data) {
    return new Promise((resolve, reject) => {
      clientCashback(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  clientStoreManualNonKatm({ commit }, data) {
    return new Promise((resolve, reject) => {
      clientStoreManualNonKatm(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
