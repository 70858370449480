import {
  index,
  store,
  update,
  destroy,
  indexColor,
  storeColor,
  updateColor,
  destroyColor,
} from '@/api/product-features.js'

export const actions = {
  index({ commit }, query) {
    return new Promise((resolve, reject) => {
      index(query)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  store({ commit }, query) {
    return new Promise((resolve, reject) => {
      store(query)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  update({ commit }, query) {
    return new Promise((resolve, reject) => {
      update(query)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  destroy({ commit }, query) {
    return new Promise((resolve, reject) => {
      destroy
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  indexColor({ commit }, query) {
    return new Promise((resolve, reject) => {
      indexColor(query)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  storeColor({ commit }, query) {
    return new Promise((resolve, reject) => {
      storeColor(query)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateColor({ commit }, query) {
    return new Promise((resolve, reject) => {
      updateColor(query)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  destroyColor({ commit }, query) {
    return new Promise((resolve, reject) => {
      destroyColor(query)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

}
