export default [
  {
    path: '/warehouse/',
    name: 'WarehouseIndex',
    component: () => import('@/views/warehouse/index.vue'),
    meta: {
      middleware: 'AuthAdmin',
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
      ],
    },
    children: [
      {
        path: 'list',
        name: 'WarehouseList',
        component: () => import('@/views/warehouse/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Склад',
          hasCreateButton: true,
          createButtonName: "Qo'shish",
          breadcrumb: [
            {
              text: 'Склад',
              active: true,
            },
          ],
        },
      },
      {
        path: 'providers',
        name: 'ProvideresList',
        component: () => import('@/views/warehouse/providers/list.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Поставщики',
          hasCreateButton: true,
          createButtonName: "Qo'shish",
        },
      },
      {
        path: 'buyers',
        name: 'BuyersList',
        component: () => import('@/views/warehouse/buyers/list.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Optom klientlar',
          hasCreateButton: true,
          createButtonName: "Qo'shish",
        },
      },
      {
        path: 'provider/:id/show',
        name: 'provider-show',
        component: () => import('@/views/warehouse/providers/show.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Поставщик',
        },
      },
      {
        path: 'parties',
        name: 'WarehouseParties',
        component: () => import('@/views/warehouse/parties/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Поступление товаров',
          breadcrumb: [
            {
              text: 'Поступление товаров',
              active: true,
            },
          ],
        },
      },
      {
        path: 'party-create',
        name: 'WarehousePartyCreate',
        component: () => import('@/views/warehouse/parties/save.vue'),
      },
      {
        path: 'party/:id/show',
        name: 'warehouseParty-show',
        component: () => import('@/views/warehouse/parties/show.vue'),
      },
      {
        path: 'party/:id/show-hired-worker',
        name: 'warehouseParty-show-hworker',
        component: () => import('@/views/warehouse/parties/show.vue'),
      },
      {
        path: 'measures',
        name: 'MueasuresList',
        component: () => import('@/views/warehouse/measures/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Ўлчов бирликлари',
          hasCreateButton: true,
          createButtonName: "Qo'shish",
        },
      },
    ],
  },
  {
    path: '/party-transfer-create',
    name: 'TransferPartyCreate',
    component: () => import('@/views/warehouse/transfer-parties/save.vue'),
  },
  {
    path: '/warehouse-transfer-product',
    name: 'WarehouseTransferProduct',
    component: () => import('@/views/warehouse/transfer-parties/index.vue'),
    meta: {
      middleware: 'AuthAdmin',
      pageTitle: 'Перемещение товаров',
    },
  },
  {
    path: '/warehouse-transfer-product/:id/show',
    name: 'WarehouseTransferProduct-show',
    component: () => import('@/views/warehouse/transfer-parties/show.vue'),
  },
  {
    path: '/party-transfer/:id/show',
    name: 'TransferPartyShow',
    component: () => import('@/views/warehouse/transfer-parties/save.vue'),
  },
  {
    path: '/all-warehouse-products',
    name: 'AllWarehouseProducts',
    component: () => import('@/views/warehouse/products/index.vue'),
    meta: {
      middleware: 'AuthAdmin',
      // pageTitle: 'Barcha omborlardagi mahsulotlari',
    },
  },
  {
    path: '/productInfo/:id',
    name: 'ProductInfo',
    component: () => import(
      '@/views/warehouse/products/productDetails/Edit.vue'
    ),
    meta: {
      pageTitle: "Mahsulot ma'lumotlari",
    },
  },
  {
    path: '/create-product',
    name: 'ProductCreate',
    component: () => import(
      '@/views/warehouse/products/productDetails/Create.vue'
    ),
    meta: {
      pageTitle: 'Mahsulot yaratish',
    },
  },
  {
    path: '/warehouse-products/:id',
    name: 'WarehouseProducts-show',
    component: () => import('@/views/warehouse/products/warehouseProducts.vue'),
    meta: {
      middleware: 'AuthAdmin',
      pageTitle: 'Ombor mahsulotlari',
      breadcrumb: [
        {
          text: 'Ombor mahsulotlari',
          active: true,
        },
      ],
    },
  },
  {
    path: '/warehouse-product-info/:id',
    name: 'WarehouseProductInfo',
    component: () => import('@/views/warehouse/products/warehouseProductInfo.vue'),
  },
  {
    path: '/hired-workers',
    name: 'HiredWorkersList',
    component: () => import('@/views/hired-worker/list.vue'),
    meta: {
      middleware: 'AuthAdmin',
      pageTitle: 'Ёлланма ишчи',
      // hasCreateButton: true,
      // createButtonName: 'Qo\'shish',
    },
  },
  {
    path: '/hired-worker/:id',
    name: 'HiredWorker-show',
    component: () => import('@/views/warehouse/providers/show.vue'),
    meta: {
      middleware: 'AuthAdmin',
      pageTitle: 'Ёлланма ишчи',
      // hasCreateButton: true,
      // createButtonName: 'Qo\'shish',
    },
  },
]
