import request from '@/utils/request'

const slug = '/warehouses/product-categories'

export function index(params) {
  return request({
    url: slug,
    method: 'get',
    params,
  })
}
export function tree(params) {
  return request({
    url: '/warehouses/product-category-tree',
    method: 'get',
    params,
  })
}

export function productsForPriceTag(params) {
  return request({
    url: '/warehouses/products-for-price-tag',
    method: 'get',
    params,
  })
}
// export function show(params) {
//   return request({
//     url: `${slug}/${params.id}`,
//     method: 'get',
//     params,
//   })
// }

export function store(data) {
  return request({
    url: slug,
    method: 'post',
    data,
  })
}

export function update(data) {
  return request({
    url: `${slug}/${data.id}`,
    method: 'put',
    data,
  })
}

export function destroy(id) {
  return request({
    url: `${slug}/${id}`,
    method: 'delete',
  })
}

export function warehouseProducts(params) {
  return request({
    url: '/warehouses/has-in-warehouse-products',
    method: 'get',
    params,
  })
}

export function warehouseProductsByWarehouse(params) {
  return request({
    url: '/warehouses/has-in-warehouse-products-by-warehouse',
    method: 'get',
    params,
  })
}

export function warehouseProductsByBarcode(params) {
  return request({
    url: `/warehouses/has-in-warehouse-product/${params.barcode}`,
    method: 'get',
    params,
  })
}
export function updateProductPrice(data) {
  return request({
    url: '/warehouses/update-price',
    method: 'put',
    data,
  })
}

export function addPhotoProduct(data) {
  return request({
    url: '/warehouses/product-category/add-photo',
    method: 'put',
    data,
  })
}
export function updatePhotoProduct(data) {
  return request({
    url: '/warehouses/product-category/update-photo',
    method: 'put',
    data,
  })
}
export function productCategoryCharacteristics(params) {
  return request({
    url: '/warehouses/product-category-characteristics',
    method: 'get',
    params,
  })
}
export function getCharacteristicsValue(params) {
  return request({
    url: '/warehouses/characteristic-values',
    method: 'get',
    params,
  })
}

export function storeCharacteristicsValue(data) {
  return request({
    url: '/warehouses/characteristic-values',
    method: 'post',
    data,
  })
}

export function show(params) {
  return request({
    url: `/shop/models-with-parents/${params.id}`,
    method: 'get',
    params,
  })
}
//
export function modelsWithParents(params) {
  return request({
    url: '/warehouses/models-with-parents',
    method: 'get',
    params,
  })
}
// new-product-categories
const newProductCatSlug = '/warehouses/new-product-categories'
export function newTree(params) {
  return request({
    url: '/warehouses/new-product-category-tree',
    method: 'get',
    params,
  })
}
export function indexNPCategories(params) {
  return request({
    url: newProductCatSlug,
    method: 'get',
    params,
  })
}

export function storeNPCategories(data) {
  return request({
    url: newProductCatSlug,
    method: 'post',
    data,
  })
}

export function updateNPCategories(data) {
  return request({
    url: `${newProductCatSlug}/${data.id}`,
    method: 'put',
    data,
  })
}

export function destroyNPCategories(id) {
  return request({
    url: `${newProductCatSlug}/${id}`,
    method: 'delete',
  })
}

export function calculateInstallmentPriceDolar(params) {
  return request({
    url: '/warehouses/calculate-installment-price-dollar',
    method: 'get',
    params,
  })
}