export const getters = {
  PERSONS: state => state.persons,
  PERSONS_COUNT: state => state.personsCount,
  BRANDS_LIST: state => state.brandsList,
  SOURECES: state => state.sources,
  TEST_PERSONS: state => state.testPersons,
  TEST_PERSONS_COUNT: state => state.testPersonsCount,
  CLIENT_REPORT: state => state.clientReport,
  CLIENT2_REPORT: state => state.client2Report,
  CLIENTS_REPORT: state => state.clientsReport,
  UNIT_OF_MEASURES: state => state.unit_of_measures,
}
