import { setItem } from '@/utils/storage';

export const mutations = {
  SET_USER: (state, user) => {
    if (user) {
      user['role_name'] = user.role ? user.role.name : 'user';
      // user['role_display_name'] = 'admin';
      // user['role_soato'] = '17';
    }
    state.user = user;
    if (state.user) {
      setItem('user', state.user);
    }
  },
  SET_IS_AUTH: (state, value) => {
    state.is_auth = value;
  },
  SET_SERVICES: (state, value) => {
    state.services = getServicesByType(value);
  },
};

function getServicesByType(services) {
  let arr = {};
  if (services) {
    services.forEach(service => {
      if (service['name'] != 'kadrlar.argos.uz') {
        if (arr[service.type]) {
          arr[service.type].push(service);
        } else {
          arr[service.type] = [service];
        }
      }
    });
  }
  return arr;
}
