import Auth from './Auth'
import AuthCashier from './AuthCashier'
import AuthDeputy from './AuthDeputy'
import AuthDirector from './AuthDirector'
import AuthLevier from './AuthLevier'
import AuthSeller from './AuthSeller'
import AuthOperator from './AuthOperator'

export default {
  Auth,
  AuthOperator,
  AuthCashier,
  AuthSeller,
  AuthDeputy,
  AuthDirector,
  AuthLevier,
}
