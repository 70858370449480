export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    meta: { middleware: 'Auth' },
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    meta: { middleware: 'Auth' },
  },
  {
    path: '/dashboard/investor',
    name: 'dashboard-investor',
    component: () => import('@/views/dashboard/investor/Ecommerce.vue'),
    meta: { middleware: 'Auth' },
  },
]
