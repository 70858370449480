import {
  roles,
  sendSms,
  confirmSms,
  reportStrategyList,
  makeReport,
  getClientReport,
  calculateCreditAmount,
  calculatediscountAmount,
  makeContractPdf,
  makeReportPart,
  calculateCreditSchedule,
  confirmForExceptionSituations,
  sendForExceptionSituations,
  makePaymentPdf,
  makeNakladnoyPdf,
  getPermissions,
  unitOfMeasures,
  storeUnitOfMeasure,
  updateUnitOfMeasure,
  destroyUnitOfMeasure,
  getVisablePaymentProducts,
  partyStoreForHiredWorker,
  getDollarRates,
  storeDollarRates,
  makeReportFromSelfShop,
  clientReports,
  getIClouds,
  getPhoneCode,
  storeICloud,
  getICloud,
  updateICloud,
  destroyICloud,
  attachICloud,
  iCloudDuplicate,
  detachICloud,
  addSignalICloud,
  addBlockICloud,
  getClickTransactions,
  getSuggestions,
  storeSuggestion,
  destroySuggestion,
  getSuggestion,
  updateSuggestion,
  getBlacklist,
  storeBlacklist,
  blacklistOut,
  getBlacklistItem,
  updateBlacklist,
  destroyBlacklistItem,
  getRealDollarRates,
  storeRealDollarRates,
  cashbacksList,

  getKnoxes,
  storeKnox,
  getKnox,
  updateKnox,
  addSignalKnox,
  addBlockKnox,
  knoxApprove,
  unblockKnox,
  updateImeiProduct,
  sendSelectedContractsUniaccess,
  sendAllOverdueContractsUniaccess,
  sendManualAmountUniaccess,
  sendManualAmountOldUniaccess,
  getAutopayClients,
  getAutopayClient,
  getAutopayPayments,
  getAutopayPayment,
  autopayPaymentReject,
  autopayToggle,
  // brands
  indexBrands,
  showBrands,
  storeBrands,
  updateBrands,
  destroyBrands,
  // brandmodels
  indexBrandModels,
  showBrandModels,
  storeBrandModels,
  updateBrandModels,
  destroyBrandModels,
  kpiEmployee
} from '@/api/resources'

export const actions = {
  roles({ commit }, data) {
    return new Promise((resolve, reject) => {
      roles(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendSms({ commit }, data) {
    return new Promise((resolve, reject) => {
      sendSms(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  confirmSms({ commit }, data) {
    return new Promise((resolve, reject) => {
      confirmSms(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  reportStrategyList({ commit }, data) {
    return new Promise((resolve, reject) => {
      reportStrategyList(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  makeReport({ commit }, data) {
    return new Promise((resolve, reject) => {
      makeReport(data)
        .then(res => {
          commit('SET_REPORT', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getClientReport({ commit }, data) {
    return new Promise((resolve, reject) => {
      getClientReport(data)
        .then(res => {
          commit('SET_REPORT1', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  calculateCreditAmount({ commit }, data) {
    return new Promise((resolve, reject) => {
      calculateCreditAmount(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  calculatediscountAmount({ commit }, data) {
    return new Promise((resolve, reject) => {
      calculatediscountAmount(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  calculateCreditSchedule({ commit }, data) {
    return new Promise((resolve, reject) => {
      calculateCreditSchedule(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  makePaymentPdf({ commit }, data) {
    return new Promise((resolve, reject) => {
      makePaymentPdf(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  makeContractPdf({ commit }, data) {
    return new Promise((resolve, reject) => {
      makeContractPdf(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendForExceptionSituations({ commit }, data) {
    return new Promise((resolve, reject) => {
      sendForExceptionSituations(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  confirmForExceptionSituations({ commit }, data) {
    return new Promise((resolve, reject) => {
      confirmForExceptionSituations(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  makeReportPart({ commit }, data) {
    return new Promise((resolve, reject) => {
      makeReportPart(data)
        .then(res => {
          if (data.type === 'clientReport') {
            commit('SET_REPORT1', res.data)
          } else {
            commit('SET_REPORT2', res.data)
          }
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // unit-of-measures
  unitOfMeasures({ commit }, data) {
    return new Promise((resolve, reject) => {
      unitOfMeasures(data)
        .then(res => {
          commit('SET_MEASURES', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  storeUnitOfMeasure({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeUnitOfMeasure(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateUnitOfMeasure({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateUnitOfMeasure(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  destroyUnitOfMeasure({ commit }, data) {
    return new Promise((resolve, reject) => {
      destroyUnitOfMeasure(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  getVisablePaymentProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      getVisablePaymentProducts(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  makeNakladnoyPdf({ commit }, data) {
    return new Promise((resolve, reject) => {
      makeNakladnoyPdf(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  getPermissions({ commit }, data) {
    return new Promise((resolve, reject) => {
      getPermissions(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  partyStoreForHiredWorker({ commit }, data) {
    return new Promise((resolve, reject) => {
      partyStoreForHiredWorker(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getDollarRates({ commit }, data) {
    return new Promise((resolve, reject) => {
      getDollarRates(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  storeDollarRates({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeDollarRates(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  makeReportFromSelfShop({ commit }, data) {
    return new Promise((resolve, reject) => {
      makeReportFromSelfShop(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getIClouds({ commit }, data) {
    return new Promise((resolve, reject) => {
      getIClouds(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  storeICloud({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeICloud(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getICloud({ commit }, data) {
    return new Promise((resolve, reject) => {
      getICloud(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateICloud({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateICloud(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  destroyICloud({ commit }, data) {
    return new Promise((resolve, reject) => {
      destroyICloud(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  attachICloud({ commit }, data) {
    return new Promise((resolve, reject) => {
      attachICloud(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  iCloudDuplicate({ commit }, data) {
    return new Promise((resolve, reject) => {
      iCloudDuplicate(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  detachICloud({ commit }, data) {
    return new Promise((resolve, reject) => {
      detachICloud(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addSignalICloud({ commit }, data) {
    return new Promise((resolve, reject) => {
      addSignalICloud(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addBlockICloud({ commit }, data) {
    return new Promise((resolve, reject) => {
      addBlockICloud(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  clientReports({ commit }, data) {
    return new Promise((resolve, reject) => {
      clientReports(data)
        .then(res => {
          commit('SET_EX_REPORT1', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getClickTransactions({ commit }, data) {
    return new Promise((resolve, reject) => {
      getClickTransactions(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPhoneCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      getPhoneCode(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Suggestions
  getSuggestions({ commit }, data) {
    return new Promise((resolve, reject) => {
      getSuggestions(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  storeSuggestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeSuggestion(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  destroySuggestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      destroySuggestion(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSuggestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      getSuggestion(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateSuggestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateSuggestion(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Blacklist
  getBlacklist({ commit }, data) {
    return new Promise((resolve, reject) => {
      getBlacklist(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  storeBlacklist({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeBlacklist(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  blacklistOut({ commit }, data) {
    return new Promise((resolve, reject) => {
      blacklistOut(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getBlacklistItem({ commit }, data) {
    return new Promise((resolve, reject) => {
      getBlacklistItem(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateBlacklist({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateBlacklist(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  destroyBlacklistItem({ commit }, data) {
    return new Promise((resolve, reject) => {
      destroyBlacklistItem(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getRealDollarRates({ commit }, data) {
    return new Promise((resolve, reject) => {
      getRealDollarRates(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  storeRealDollarRates({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeRealDollarRates(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  cashbacksList({ commit }, data) {
    return new Promise((resolve, reject) => {
      cashbacksList(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // knox
  getKnoxes({ commit }, data) {
    return new Promise((resolve, reject) => {
      getKnoxes(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  storeKnox({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeKnox(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getKnox({ commit }, data) {
    return new Promise((resolve, reject) => {
      getKnox(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateKnox({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateKnox(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addSignalKnox({ commit }, data) {
    return new Promise((resolve, reject) => {
      addSignalKnox(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addBlockKnox({ commit }, data) {
    return new Promise((resolve, reject) => {
      addBlockKnox(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  unblockKnox({ commit }, data) {
    return new Promise((resolve, reject) => {
      unblockKnox(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateImeiProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateImeiProduct(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  knoxApprove({ commit }, data) {
    return new Promise((resolve, reject) => {
      knoxApprove(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendSelectedContractsUniaccess({ commit }, data) {
    return new Promise((resolve, reject) => {
      sendSelectedContractsUniaccess(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendAllOverdueContractsUniaccess({ commit }, data) {
    return new Promise((resolve, reject) => {
      sendAllOverdueContractsUniaccess(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendManualAmountUniaccess({ commit }, data) {
    return new Promise((resolve, reject) => {
      sendManualAmountUniaccess(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendManualAmountOldUniaccess({ commit }, data) {
    return new Promise((resolve, reject) => {
      sendManualAmountOldUniaccess(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  getAutopayClients({ commit }, data) {
    return new Promise((resolve, reject) => {
      getAutopayClients(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAutopayClient({ commit }, data) {
    return new Promise((resolve, reject) => {
      getAutopayClient(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAutopayPayments({ commit }, data) {
    return new Promise((resolve, reject) => {
      getAutopayPayments(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAutopayPayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      getAutopayPayment(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  autopayPaymentReject({ commit }, data) {
    return new Promise((resolve, reject) => {
      autopayPaymentReject(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  autopayToggle({ commit }, data) {
    return new Promise((resolve, reject) => {
      autopayToggle(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // brands
  indexBrands({ commit }, data) {
    return new Promise((resolve, reject) => {
      indexBrands(data)
        .then(res => {
          commit('SET_BRANDS_LIST', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  showBrands({ commit }, data) {
    return new Promise((resolve, reject) => {
      showBrands(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  storeBrands({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeBrands(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateBrands({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateBrands(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  destroyBrands({ commit }, data) {
    return new Promise((resolve, reject) => {
      destroyBrands(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // brand-models
  indexBrandModels({ commit }, data) {
    return new Promise((resolve, reject) => {
      indexBrandModels(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  showBrandModels({ commit }, data) {
    return new Promise((resolve, reject) => {
      showBrandModels(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  storeBrandModels({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeBrandModels(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateBrandModels({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateBrandModels(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  destroyBrandModels({ commit }, data) {
    return new Promise((resolve, reject) => {
      destroyBrandModels(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  kpiEmployee({ commit }, data) {
    return new Promise((resolve, reject) => {
      kpiEmployee(data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
