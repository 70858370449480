import request from '@/utils/request'

const slug = '/warehouses/warehouses'

export function index(params) {
  return request({
    url: slug,
    method: 'get',
    params,
  })
}

export function show(id) {
  return request({
    url: `${slug}/${id}`,
    method: 'get',
  })
}

export function store(data) {
  return request({
    url: slug,
    method: 'post',
    data,
  })
}

export function update(data) {
  return request({
    url: `${slug}/${data.id}`,
    method: 'put',
    data,
  })
}

export function destroy(id) {
  return request({
    url: `${slug}/${id}`,
    method: 'delete',
  })
}
// transfer
const transferSlug = '/warehouses/product-transfer-parties'
export function transferProductsList(params) {
  return request({
    url: transferSlug,
    method: 'get',
    params,
  })
}

export function transferProductsShow(params) {
  return request({
    url: `${transferSlug}/${params.id}`,
    method: 'get',
    params,
  })
}

export function transferProductsStore(data) {
  return request({
    url: transferSlug,
    method: 'post',
    data,
  })
}

export function getVisiblePartyProducts(params) {
  return request({
    url: '/warehouses/get-visible-party-products',
    method: 'get',
    params,
  })
}
export function getVisibleContractProducts(params) {
  return request({
    url: '/warehouses/get-visible-contract-products',
    method: 'get',
    params,
  })
}
export function getContractProducts(params) {
  return request({
    url: '/warehouses/contract-products',
    method: 'get',
    params,
  })
}
export function rejectProduct(params) {
  return request({
    url: '/warehouses/reject-product',
    method: 'put',
    params,
  })
}

export function rejectProductHiredWorker(params) {
  return request({
    url: '/warehouses/reject-product-hired-worker',
    method: 'put',
    params,
  })
}

export function rejectProductTransfer(params) {
  return request({
    url: '/warehouses/reject-product-transfer',
    method: 'put',
    params,
  })
}

export function confirmTransferProducts(params) {
  return request({
    url: `${transferSlug}/${params.product_transfer_id}`,
    method: 'put',
    params,
  })
}
