export function formatPrice(value) {
  if (value) {
    const fractionDigits = !Number.isInteger(1 * value) ? 2 : 0
    const val = (value / 1).toFixed(fractionDigits).replace(' ', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',')
  }
  return 0
}
export function toFirstUpper(value) {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
  return value
}
export function toLocaleDateString(value) {
  if (value) {
    return new Date(value).toLocaleDateString()
  }
  return value
}
