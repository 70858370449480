import request from '@/utils/request'

const slug = '/warehouses/product-memory'
const slug2 = '/warehouses/product-color'

export function index(params) {
  return request({
    url: slug,
    method: 'get',
    params,
  })
}


export function store(data) {
  return request({
    url: slug,
    method: 'post',
    data,
  })
}

export function update(data) {
  return request({
    url: `${slug}/${data.id}`,
    method: 'put',
    data,
  })
}

export function destroy(id) {
  return request({
    url: `${slug}/${id}`,
    method: 'delete',
  })
}

export function indexColor(params) {
  return request({
    url: slug2,
    method: 'get',
    params,
  })
}
export function storeColor(data) {
  return request({
    url: slug2,
    method: 'post',
    data,
  })
}
export function updateColor(data) {
  return request({
    url: `${slug2}/${data.id}`,
    method: 'put',
    data,
  })
}
export function destroyColor(id) {
  return request({
    url: `${slug2}/${id}`,
    method: 'delete',
  })
}
