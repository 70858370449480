export const mutations = {
  SET_ITEM: (state, item) => {
    state.item = item
  },
  SET_ITEMS: (state, items) => {
    state.items = {
      data: items,
    }
  },
  SET_ITEM_NUMBER: (state, item) => {
    state.item_num = item
  },
  SET_ITEMS_NUMBER: (state, items) => {
    state.items_num = {
      data: items,
    }
  },
}
