import request from '@/utils/request'

const slug = '/seven-tech-devices'
const slug1 = '/seven-tech-device'

export function index(params) {
  return request({
    url: slug,
    method: 'get',
    params,
  })
}

export function show(params) {
  return request({
    url: `${slug}/${params.id}`,
    method: 'get',
    params,
  })
}

export function store(data) {
  return request({
    url: slug,
    method: 'post',
    data,
  })
}

export function update(data) {
  return request({
    url: `${slug}/${data.id}`,
    method: 'put',
    data,
  })
}

export function getInfo(data) {
  return request({
    url: `${slug1}/get-info`,
    method: 'put',
    data,
  })
}

export function addBlock(data) {
  return request({
    url: `${slug1}/add-block`,
    method: 'put',
    data,
  })
}

export function unBlock(data) {
  return request({
    url: `${slug1}/unblock`,
    method: 'put',
    data,
  })
}

export function push(data) {
  return request({
    url: `${slug1}/push`,
    method: 'put',
    data,
  })
}
