export default [
  {
    path: '/client/create',
    name: 'ClientCreate',
    component: () => import('@/views/client/create.vue'),
    meta: {
      middleware: 'AuthSeller',
      pageTitle: 'Регистрация клиента',
    },
  },
  {
    path: '/clients',
    name: 'ClientIndex',
    component: () => import('@/views/client/index.vue'),
    meta: {
      middleware: 'Auth',
      pageTitle: 'Список клиентов',
    },
  },
  {
    path: '/client/:id',
    name: 'client-show',
    component: () => import('@/views/client/show.vue'),
    meta: {
      middleware: 'AuthSeller',
      pageTitle: 'Информации клиента',
    },
  },
  {
    path: '/contracts',
    name: 'OrdersIndex',
    component: () => import('@/views/payment/index.vue'),
    meta: {
      middleware: 'Auth',
      pageTitle: 'Список договора',
      breadcrumb: [
        {
          text: 'Список договора',
          active: true,
        },
      ],
    },
  },
  {
    path: '/old-contracts',
    name: 'OldOrdersIndex',
    component: () => import('@/views/payment/oldPayments.vue'),
    meta: {
      middleware: 'Auth',
      pageTitle: 'Эски шартномалар',
      breadcrumb: [
        {
          text: 'Эски шартномалар',
          active: true,
        },
      ],
    },
  },
  {
    path: '/brought-to-court',
    name: 'BroughtToCourt',
    component: () => import('@/views/courts/index.vue'),
    meta: {
      middleware: 'Auth',
      pageTitle: 'Sudga oshirilganlar',
      breadcrumb: [
        {
          text: 'Sudga oshirilganlar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/old-brought-to-court',
    name: 'OldBroughtToCourt',
    component: () => import('@/views/courts/oldIndex.vue'),
    meta: {
      middleware: 'Auth',
      pageTitle: 'Sudga oshirilganlar eski shartnomalar uchun',
    },
  },
  {
    path: '/order-create',
    name: 'OrderCreate',
    component: () => import('@/views/order/create.vue'),
    meta: {
      middleware: 'Auth',
    },
  },
  {
    path: '/order-create-cash',
    name: 'OrderCreateCash',
    component: () => import('@/views/order/createForCash.vue'),
    meta: {
      middleware: 'AuthSeller',
    },
  },
  {
    path: '/cash-order/:id',
    name: 'CashOrder-show',
    component: () => import('@/views/order/cashShow.vue'),
    meta: {
      middleware: 'AuthSeller',
    },
  },
  {
    path: '/order/:id',
    name: 'OrderShow',
    component: () => import('@/views/order/show.vue'),
    meta: {
      middleware: 'AuthSeller',
    },
  },
  {
    path: '/contract-passport-confirmation',
    name: 'ConfirmContractPhotos',
    component: () => import('@/views/order/confirmation.vue'),
    meta: {
      middleware: 'Auth',
      pageTitle: 'Подтверждение договора',
    },
  },
  {
    path: '/contract-passport-confirmation/:id',
    name: 'ConfirmContractPhotos-show',
    component: () => import('@/views/order/components/show_confirmation.vue'),
    meta: {
      middleware: 'Auth',
      pageTitle: 'Подтверждение договора',
    },
  },
]
