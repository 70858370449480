import request from '@/utils/request'
import requestAuthApi from '@/utils/requestAuthApi'

export function currentUser() {
  return request({
    url: '/auth/me',
    method: 'get',
  })
}
export function roleServices() {
  return requestAuthApi({
    url: '/role-services',
    method: 'get',
  })
}
export function login(data) {
  return request({
    url: 'auth/login',
    method: 'post',
    data,
  })
}
