export const mutations = {
  SET_PLUSE_REQUEST_RUNNING: state => {
    state.requestsRunning++;
  },
  SET_MINUS_REQUEST_RUNNING: state => {
    if (state.requestsRunning > 0) {
      setTimeout(() => {
        state.requestsRunning--;
      }, 500);
    }
  }
};
