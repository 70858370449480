import store from '@/store'
import errors from '@/utils/errors'
import { showToast } from '@/utils/toast'

export function errorsAlert(error) {
  const validateErrors = getValidateErrors(error)
  if (error.status == 422 && validateErrors) {
    showToast('danger', validateErrors, 'XIcon')
  } else if (error && error.data && error.data.code) {
    const message = getMessage(error.data.code)
    if (message) {
      showToast('danger', message, 'XIcon')
    } else if (!message && error.data.message) {
      showToast('danger', error.data.message, 'XIcon')
    }
  } else if (error.status && error.statusText) {
    if (error.data.error && (error.data.error == 'invalid_credentials')) {
      showToast('danger', errors[401], 'XIcon')
    } else if (error.data && error.data.message) {
      showToast('danger', error.data.message, 'XIcon')
    } else {
      showToast('danger', error.statusText, 'XIcon')
    }
  }
}

function getMessage(code) {
  const error = getErrorByCode(code)
  return error.ru
}

function getErrorByCode(code) {
  const apiErrors = store.getters['errors/ERRORS']
  if (apiErrors && apiErrors.length) {
    return apiErrors.find(err => err.id == code)
  }
  return null
}

function getValidateErrors(error) {
  if (error.status == 422 && error.data && error.data.errors) {
    let message = ''
    Object.keys(error.data.errors).forEach(filed => {
      message += error.data.errors[filed].join('. ')
    })
    return message
  }
  return null
}
