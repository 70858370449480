<template>
  <div />
</template>

<script>
// import { onMessage, getToken } from 'firebase/messaging'
// import { showToast } from '@/utils/toastNotification'
import pusher from '@/utils/pusher'

export default {
  name: 'Notification',
  async mounted() {
    setTimeout(() => {
      const channel = pusher.subscribe('notificaton_channel')

      console.log('66666', channel)
      // Listen for an event
      channel.bind('SendNotification', data => {
        console.log('7777', data)
      // this.messages.push(data) // Add received message to the array
      })
      channel.bind('App/Events/SendNotification', data => {
        console.log('8888', data)
      // this.messages.push(data) // Add received message to the array
      })
    }, 1000)
  },
  beforeDestroy() {
    // Unbind the event and unsubscribe when the component is destroyed
    const channel = pusher.subscribe('notificaton_channel')
    channel.unbind('SendNotification')
    pusher.unsubscribe('notificaton_channel')
  },
}
</script>

<style scoped></style>
