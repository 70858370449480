import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVue } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import { VueMaskDirective } from 'v-mask'
import { formatPrice, toLocaleDateString } from '@/filters/report'
import globalMixin from '@/@core/mixins/global'
import router from './router'
import store from './store'
import App from './App.vue'

// Eleement-ui
import './plugins/ElementUi'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'
import '@core/directives/permissions'

// Mxins
Vue.mixin(globalMixin)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
// Composition API
Vue.use(VueCompositionAPI)
Vue.filter('formatPrice', formatPrice)
Vue.filter('toLocaleDateString', toLocaleDateString)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')
// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.directive('mask', VueMaskDirective)

window.onerror = function (msg, url, lineNo, columnNo, error) {
  const err_msg = "Uncaught SyntaxError: Unexpected token '<'"
  const err_url = '/js/chunk-'
  const currentDate = Math.floor(Date.now() / 1000)
  const chunk_err = localStorage.getItem('chunk_err_date')

  const message = [
    `Message: ${msg}`,
    `URL: ${url}`,
    `Line: ${lineNo}`,
    `Column: ${columnNo}`,
    `Error object: ${JSON.stringify(error)}`,
  ].join(' - ')
  console.log('window.onerror', message)

  if (msg.includes(err_msg) && url.includes(err_url)) {
    const secund = 300 // 5 - minutes
    if (chunk_err && chunk_err + secund < currentDate) {
      localStorage.setItem('chunk_err_date', currentDate)
      location.reload()
    } else if (!chunk_err) {
      localStorage.setItem('chunk_err_date', currentDate)
      location.reload()
    }
  }
}
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
