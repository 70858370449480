import request from '@/utils/request'

const slug = '/imei-devices'

export function index(params) {
  return request({
    url: slug,
    method: 'get',
    params,
  })
}

export function show(params) {
  return request({
    url: `${slug}/${params.id}`,
    method: 'get',
    params,
  })
}

export function store(data) {
  return request({
    url: slug,
    method: 'post',
    data,
  })
}

export function update(data) {
  return request({
    url: `${slug}/${data.id}`,
    method: 'put',
    data,
  })
}

export function destroy(id) {
  return request({
    url: `${slug}/${id}`,
    method: 'delete',
  })
}

export function addBlock(data) {
  return request({
    url: '/imei-device/add-block',
    method: 'put',
    data,
  })
}

export function unBlock(data) {
  return request({
    url: '/imei-device/unblock',
    method: 'put',
    data,
  })
}

export function check(data) {
  return request({
    url: '/imei-device/check',
    method: 'put',
    data,
  })
}

export function checkAttach(data) {
  return request({
    url: '/imei-device/check-attach',
    method: 'put',
    data,
  })
}
