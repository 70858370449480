// eslint-disable-next-line import/extensions
import { getUniques } from '@/utils/index.js'

export const mutations = {
  SET_PERSONS: (state, data) => {
    let persons = data.data.data
    state.personsCount = data.data.count
    persons = persons.map(item => {
      const source = state.sources.find(constant => constant.id == item.source)
      item.source_name = source ? source.name : ''
      item.profile_name = item.company ? item.company.name : item.profile_name
      return item
    })
    state.persons = getUniques(persons)
  },
  SET_PERSONS_COUNT: (state, total) => {
    state.personsCount = total
  },
  SET_BRANDS_LIST:(state, data)=>{
    state.brandsList = data.data.map(el => ({ value: el.id, text: el.name }))
  },
  SET_TEST_PERSONS: (state, data) => {
    if (data.data) {
      const persons = data.data.data
      const { page } = data
      if (page == 1) {
        state.testPersonsCount = data.data.count ? data.data.count : 0
      }
      if (page > 1) {
        state.testPersons = getUniques(state.testPersons.concat(persons))
      } else {
        state.testPersons = getUniques(persons)
      }
    }
  },
  SET_REPORT: (state, data) => {
    state.clientsReport = data
    state.clientReport = data && data.clientReport ? data.clientReport : null
    state.client2Report = data && data.client2Report ? data.client2Report : null
  },
  SET_REPORT1: (state, data) => {
    state.clientReport = data
  },
  SET_EX_REPORT1: (state, data) => {
    if (data && data.data.length) {
      state.clientReport = data.data[0]
    }
  },
  SET_REPORT2: (state, data) => {
    state.client2Report = data
  },
  SET_MEASURES: (state, data) => {
    state.unit_of_measures = data
  },
}
