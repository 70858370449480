export default [
  {
    path: '/settings/',
    name: 'Settings',
    component: () => import('@/views/settings/index.vue'),
    meta: {
      middleware: 'AuthAdmin',
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
      ],
    },
    children: [
      {
        path: 'regions',
        name: 'SettingsRegion',
        component: () => import('@/views/settings/region/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Regions',
          hasCreateButton: true,
          createButtonName: 'Qo\'shish',
          breadcrumb: [
            {
              text: 'Regions',
              active: true,
            },
          ],
        },
      },
      {
        path: 'print/:barcode',
        name: 'Print',
        component: () => import('@/views/print/print.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Распечатать штрих-код',
        },
      },
      {
        path: 'product-categories',
        name: 'SettingsProductCategory',
        component: () => import('@/views/settings/product-category/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Mahsulot turlari',
          breadcrumb: [
            {
              text: 'Mahsulot turlari',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new-product-categories',
        name: 'SettingsNewProductCategory',
        component: () => import('@/views/settings/new-product-category/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Mahsulot turlari',
          breadcrumb: [
            {
              text: 'Mahsulot turlari',
              active: true,
            },
          ],
        },
      },
      {
        path: 'product-print',
        name: 'SettingsProductPrint',
        component: () => import('@/views/settings/print-product/index.vue'),
        meta: {
          middleware: 'Auth',
          breadcrumb: [
            {
              text: 'Narxni chop etish',
              active: true,
            },
          ],
        },
      },
      {
        path: 'iCloud-list',
        name: 'iCloudList',
        component: () => import('@/views/settings/iCloud/index.vue'),
        meta: {
          middleware: 'Auth',
          hasCreateButton: true,
          createButtonName: 'Qo\'shish',
          pageTitle: 'iCloud',
          permissions: 'iClouds.index',
          breadcrumb: [
            {
              text: 'iCloud`lar ro`yhati',
              active: true,
            },
          ],
        },
      },
      {
        path: 'iCloud-show/:id',
        name: 'iCloud-show',
        component: () => import('@/views/settings/iCloud/show.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'iCloud',
          permissions: 'iClouds.show',
        },
      },
      {
        path: 'knoxes-list',
        name: 'KnoxesList',
        component: () => import('@/views/settings/knox/index.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Knox',
          permissions: 'knoxes.index',
          breadcrumb: [
            {
              text: 'Knox`lar ro`yhati',
              active: true,
            },
          ],
        },
      },
      {
        path: 'knox/:id',
        name: 'knox-show',
        component: () => import('@/views/settings/knox/show.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Knox',
          permissions: 'knoxes.show',
        },
      },
      {
        path: 'uzimei-list',
        name: 'UzimeiList',
        component: () => import('@/views/settings/uzimei/index.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'UzIMEI`ga ulangan qurilmalar ro`yhati',
          permissions: 'imei_devices.index',
        },
      },
      {
        path: 'uzimei/:id',
        name: 'uzimei-show',
        component: () => import('@/views/settings/uzimei/show.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'UzIMEI',
          permissions: 'imei_devices.show',
        },
      },
      // 7 tech
      {
        path: 'sevenTech-list',
        name: 'SevenTechList',
        component: () => import('@/views/settings/sevenTech/index.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Seven Tech`ga ulangan qurilmalar ro`yhati',
          permissions: 'seven_tech_devices.index',
        },
      },
      {
        path: 'sevenTech/:id',
        name: 'sevenTech-show',
        component: () => import('@/views/settings/sevenTech/show.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Seven Tech',
          permissions: 'seven_tech_devices.show',
        },
      },
      {
        path: 'warehouse',
        name: 'SettingsWareHouse',
        component: () => import('@/views/settings/warehouse/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Склад',
          hasCreateButton: true,
          permissions: 'warehouses.warehouses-store',
          createButtonName: 'Qo\'shish',
          breadcrumb: [
            {
              text: 'Склад',
              active: true,
            },
          ],
        },
      },
      {
        path: 'sms-template',
        name: 'SettingsSmsTemplate',
        component: () => import('@/views/settings/sms-template/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Смс шаблоны',
          hasCreateButton: true,
          permissions: 'settings.sms_templates-store',
          createButtonName: 'Qo\'shish',
          breadcrumb: [
            {
              text: 'Смс шаблоны',
              active: true,
            },
          ],
        },
      },
      {
        path: 'sms-send',
        name: 'SettingsSmsSend',
        component: () => import('@/views/settings/sms-send/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Смс юбориш',
          permissions: 'sms.send-sms-messages',
        },
      },
      {
        path: 'products',
        name: 'SettingsProduct',
        component: () => import('@/views/settings/product/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Products',
          hasCreateButton: true,
          createButtonName: 'Qo\'shish',
          breadcrumb: [
            {
              text: 'Products',
              active: true,
            },
          ],
        },
      },
      {
        path: 'users',
        name: 'SettingsUsers',
        component: () => import('@/views/settings/user/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Пользаватель',
          hasCreateButton: true,
          permissions: 'settings.users-store',
          createButtonName: 'Qo\'shish',
          breadcrumb: [
            {
              text: 'Пользаватель',
              active: true,
            },
          ],
        },
      },
      {
        path: 'roles',
        name: 'SettingsRoles',
        component: () => import('@/views/settings/role/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Роли',
          permissions: 'settings.roles-store',
          hasCreateButton: true,
          createButtonName: 'Qo\'shish',
          breadcrumb: [
            {
              text: 'Роли',
              active: true,
            },
          ],
        },
      },
      {
        path: 'attach-letter-to-users',
        name: 'SettingsAttachLetterToUsers',
        component: () => import('@/views/settings/attach-letter/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Harf biriktirish',
          permissions: 'settings.levy_user_letters-index',
        },
      },
      {
        path: 'attach-number-to-users',
        name: 'SettingsAttachNumberToUsers',
        component: () => import('@/views/settings/attach-number/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Raqam biriktirish',
          permissions: 'settings.levy_user_letters-index',
        },
      },
      {
        path: 'settings',
        name: 'setting-index',
        component: () => import('@/views/settings/setting/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Системные настройки',
          hasCreateButton: true,
          permissions: 'settings.settings-update',
          createButtonName: 'Qo\'shish',
          breadcrumb: [
            {
              text: 'Системные настройки',
              active: true,
            },
          ],
        },
      },
      {
        path: 'dollar-rates',
        name: 'DollarRates',
        component: () => import('@/views/settings/dollar-rates/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Валюта курси',
          hasCreateButton: true,
          createButtonName: 'Qo\'shish',
          breadcrumb: [
            {
              text: 'Валюта курси',
              active: true,
            },
          ],
        },
      },
      {
        path: 'phone-codes',
        name: 'PhoneCodes',
        component: () => import('@/views/settings/phone-codes/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          permissions: 'settings.phone-codes',
          pageTitle: 'Telefon kodlari',
        },
      },
      {
        path: 'brand-models/:id',
        name: 'brands-show',
        component: () => import('@/views/settings/new-product-category/brand_models/index.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Brend modellari',
        },
      },
    ],
  },
]
