import request from '@/utils/request'

const banner = '/settings/shop-gallery-images'

export function index(params) {
  return request({
    url: banner,
    method: 'get',
    params,
  })
}

export function show(params) {
  return request({
    url: `${banner}/${params.id}`,
    method: 'get',
    params,
  })
}

export function store(data) {
  return request({
    url: banner,
    method: 'post',
    data,
  })
}

export function update(data) {
  return request({
    url: `${banner}/${data.id}`,
    method: 'put',
    data,
  })
}

export function destroy(id) {
  return request({
    url: `${banner}/${id}`,
    method: 'delete',
  })
}
//
export function qOrdersList(query) {
  return request({
    url: '/quick-orders',
    method: 'get',
    params: query,
  })
}

export function qOrderShow(query) {
  return request({
    url: `/quick-orders/${query.id}`,
    method: 'get',
    params: query,
  })
}
export function qOrderUpdate(data) {
  return request({
    url: `/quick-orders/${data.id}`,
    method: 'put',
    data,
  })
}
export function qOrderDestroy(id) {
  return request({
    url: `/quick-orders/${id}`,
    method: 'delete',
  })
}

// tg chat
export function getChats(query) {
  return request({
    url: '/chat/get-chats',
    method: 'get',
    params: query,
  })
}
export function getTgMessages(query) {
  return request({
    url: '/chat/get-all-messages',
    method: 'get',
    params: query,
  })
}
export function getTgMessage(query) {
  return request({
    url: `/chat/get-messages/${query.id}`,
    method: 'get',
    params: query,
  })
}
export function sendTgMessage(data) {
  return request({
    url: '/chat/send-message',
    method: 'post',
    data,
  })
}
export function readMessage(id) {
  return request({
    url: `/chat/read-chat/${id}`,
    method: 'post',
  })
}
export function getTgOrder(query) {
  return request({
    url: `/chat/get-order/${query.id}`,
    method: 'get',
    params: query,
  })
}
export function getTgMessageByOrder(query) {
  return request({
    url: `/chat/get-message-by-order/${query.id}`,
    method: 'get',
    params: query,
  })
}
