export const mutations = {
  SET_ITEM: (state, item) => {
    state.item = item
  },
  SET_TREE: (state, tree) => {
    state.tree = tree
    if (tree && tree.length) {
      const mappedRows = tree.map(el => elementRow(el))
      state.tree_rows = mappedRows.map(el => {
        const newChildRow = el
        if (el._children) {
          newChildRow._children = el._children.map(child => elementRow(child))
          el._children.map(child => {
            const newChild2Row = child
            if (child._children) {
              newChild2Row._children = child._children.map(child => elementRow(child))
            }
            return newChild2Row
          })
        }
        return newChildRow
      })
    }
  },
  SET_NEW_TREE:(state, tree) => {
    state.new_tree = tree
    if (tree && tree.length) {
      const mappedRows = tree.map(el => elementRow(el))
      state.new_tree_rows = mappedRows.map(el => {
        const newChildRow = el
        if (el._children) {
          newChildRow._children = el._children.map(child => elementRow(child))
          el._children.map(child => {
            const newChild2Row = child
            if (child._children) {
              newChild2Row._children = child._children.map(child => elementRow(child))
            }
            return newChild2Row
          })
        }
        return newChildRow
      })
    }
  },
  SET_ITEMS: (state, items) => {
    state.items = {
      data: items.data,
      total: items.total,
      page: items.current_page,
      per_page: items.per_page,
    }
  },
  SET_WAREHOUSE_PRODUCTS: (state, items) => {
    state.warehouse_products = items.data
  },
  SET_WAREHOUSE_PRODUCTS_BY_WAAREHOUSE: (state, items) => {
    state.warehouse_products_by_warehouse = items.data
  },
}

function elementRow(el) {
  return {
    name: el.title,
    id: el.id,
    colde: el.id,
    _showChildren: !!(el.children),
    _hasChildren: !!(el.children),
    _children: el.children,
  }
}
