import request from '@/utils/request'

const slug = '/settings/levy-user-letters'
const slugNum = '/settings/operator-user-numbers'

export function index(params) {
  return request({
    url: slug,
    method: 'get',
    params,
  })
}

export function show(id) {
  return request({
    url: `${slug}/${id}`,
    method: 'get',
  })
}

export function store(data) {
  return request({
    url: slug,
    method: 'post',
    data,
  })
}

export function update(data) {
  return request({
    url: `${slug}/${data.id}`,
    method: 'put',
    data,
  })
}

export function destroy(id) {
  return request({
    url: `${slug}/${id}`,
    method: 'delete',
  })
}

// attach number

export function indexNum(params) {
  return request({
    url: slugNum,
    method: 'get',
    params,
  })
}

export function showNum(id) {
  return request({
    url: `${slugNum}/${id}`,
    method: 'get',
  })
}

export function storeNum(data) {
  return request({
    url: slugNum,
    method: 'post',
    data,
  })
}

export function updateNum(data) {
  return request({
    url: `${slugNum}/${data.id}`,
    method: 'put',
    data,
  })
}

export function destroyNum(id) {
  return request({
    url: `${slugNum}/${id}`,
    method: 'delete',
  })
}
