export default [
  {
    path: '/investment/',
    name: 'Investment',
    component: () => import('@/views/investment/index.vue'),
    permissions: 'investment',
    children: [
      {
        path: 'investors',
        name: 'Investors',
        component: () => import('@/views/investment/investors/index.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Investorlar',
          hasCreateButton: true,
          permissions: 'investment.investors-index',
          createButtonName: 'Qo\'shish',
        },
      },
      {
        path: 'investor/:id',
        name: 'investor-show',
        component: () => import('@/views/investment/investors/show.vue'),
        meta: {
          middleware: 'AuthAdmin',
          pageTitle: 'Investor',
        },
      },
    ],
  },
]
